const { languageCodes } = require('./test')

const LANGUAGE_EN = languageCodes.ENGLISH
const LANGUAGE_ES = languageCodes.SPANISH
const LANGUAGE_DE = languageCodes.GERMAN
const LANGUAGE_FR = languageCodes.FRENCH
const LANGUAGE_ZH = languageCodes.MANDARIN
const LANGUAGE_KO = languageCodes.KOREAN
const LANGUAGE_AR = languageCodes.ARABIC
const ENGLISH = 'english'
const SPANISH = 'spanish'
const FRENCH = 'french'
const GERMAN = 'german'
const MANDARIN = 'mandarin'
const ARABIC = 'arabic'
const KOREAN = 'korean'

const LANGUAGES_OPTIONS = [
  {
    value: LANGUAGE_EN,
    label: 'English',
  },
  {
    value: LANGUAGE_ES,
    label: 'Spanish',
  },
  {
    value: LANGUAGE_FR,
    label: 'French',
  },
  {
    value: LANGUAGE_DE,
    label: 'German',
  },
  {
    value: LANGUAGE_ZH,
    label: 'Mandarin',
  },
  {
    value: LANGUAGE_AR,
    label: 'Arabic',
  },
  {
    value: LANGUAGE_KO,
    label: 'Korean',
  },
]

const VOICE_LANGUAGE_OPTIONS = [
  {
    value: ENGLISH,
    label: 'English',
  },
  {
    value: SPANISH,
    label: 'Spanish',
  },
  {
    value: FRENCH,
    label: 'French',
  },
  {
    value: GERMAN,
    label: 'German',
  },
  {
    value: MANDARIN,
    label: 'Mandarin',
  },
  {
    value: ARABIC,
    label: 'Arabic',
  },
  {
    value: KOREAN,
    label: 'Korean',
  },
]
module.exports = {
  LANGUAGE_EN,
  LANGUAGE_ES,
  LANGUAGE_DE,
  LANGUAGE_FR,
  LANGUAGES_OPTIONS,
  VOICE_LANGUAGE_OPTIONS,
  ENGLISH,
  SPANISH,
  GERMAN,
}
